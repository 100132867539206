import {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {format} from 'date-fns';
import {useAppDispatch} from '../../../store/app-hooks';
import useProgramFromParams from '../../../hooks/useProgramFromParams';
import useConfirm from '../../../hooks/useConfirm';
import {useNotification} from '../../../store/notification/NotificationContext';
import {deleteProgram} from '../../../store/programs/programsSlice';

import s from './index.module.scss';
import ProgramModal from '../../../components/shared/ProgramModal';
import {Button} from '../../../components/UI/Button';
import {Icon} from '../../../components/UI/Icon';
import {IProgramsForm} from '../../../components/shared/ProgramModal/formModel';
import {FIELD_LABELS} from '../../../constants';


export const ProgramDetailsContent: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {confirm} = useConfirm();
    const program = useProgramFromParams();

    const {addNotification} = useNotification();

    const [openEditModal, setOpenEditModal] = useState(false);

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION, FAIL_PROGRAM_DELETION} = FIELD_LABELS;

    const programModalData: IProgramsForm | undefined = program && {
        id: program.id,
        name: program.name,
        site_type_id: program.site_type?.id,
    };

    const onDeleteProgram = async () => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result && program) {
            dispatch(deleteProgram(program.id))
                .unwrap()
                .then(() => {
                    addNotification(SUCCESSFUL_DELETION, 'success');
                    navigate('/programs');
                })
                .catch((err) => {
                    
                    if (err.message === 'Program has associated cleanups and cannot be deleted.') {
                        addNotification(FAIL_PROGRAM_DELETION, 'error');
                    } else {
                        addNotification(FAIL_DELETION, 'error');
                    }
                    
                });
        }
    };

    return (
        <>
            <div className={s.infoContainer}>
                <Icon name="ImagePlaceholder" viewbox="0 0 150 150" size="300" />

                <div className={s.details}>
                    <div className={s.row}>
                        <div className={s.title}>Basic Information</div>
                        <Button label="Change Logo" variant="Tertiary" />
                        <Button
                            label="Edit Info"
                            onClick={() => setOpenEditModal(true)}
                            variant="Secondary"
                        />
                    </div>
                    <div className={`${s.row} ${s.highlighted}`}>
                        <div className={s.label}>Name</div>
                        <div>{program?.name}</div>
                    </div>
                    <div className={`${s.row} ${s.highlighted}`}>
                        <div className={s.label}>Created at</div>
                        <div>{program && format(new Date(program.created_at), 'dd-MM-yyyy')}</div>
                    </div>
                    <div className={`${s.row} ${s.highlighted}`}>
                        <div className={s.label}>Clean Up Site Type</div>
                        <div>{program?.site_type?.title}</div>
                    </div>
                </div>
            </div>

            <div className={s.dangerZone}>
                <div className={s.title}>Danger Zone</div>
                <div className={s.info}>
                    <p>If you delete all configurations will be lost</p>
                    <Button onClick={onDeleteProgram} label="Delete Program" variant="Danger" />
                </div>
            </div>
            {openEditModal && (
                <ProgramModal
                    program={programModalData}
                    closeModal={() => setOpenEditModal(false)}
                />
            )}
        </>
    );
};
